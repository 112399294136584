(() => {
	const refs = {
		openMenu: document.querySelector("[data-menu-open]"),
		closeMenu: document.querySelector("[data-menu-close]"),
		menu: document.querySelector("[data-menu]"),
		menuItems: document.querySelectorAll("[data-menu] .burger-menu_item"),
	};

	refs.openMenu.addEventListener("click", toggleMenu);
	refs.closeMenu.addEventListener("click", toggleMenu);
	refs.menuItems.forEach((item) => {
		item.addEventListener("click", toggleMenu);
	});

	refs.menu.addEventListener("click", (e) => {
		if (e.target.classList.contains("active")) {
			toggleMenu();
		}
	});

	function toggleMenu() {
		refs.menu.classList.toggle("active");

		if (refs.menu.classList.contains("active")) {
			document.body.style.overflow = "hidden";
			refs.openMenu.classList.add("active")
		} else {
			document.body.style.overflow = "";
						refs.openMenu.classList.remove("active")

		}
	}
})();
